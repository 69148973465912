"use strict";

$(document).ready(function () {
  /* Components */
  $(function () {
    // Validation button click
    $('body').on('click', '.js-btn-submit', function () {
      var hasError = false;
      var closestForm = $(this).closest('form'); // Validation - Empty

      closestForm.find('.js-required').each(function () {
        if ($(this).val().length === 0) {
          $(this).addClass('input__invalid');
          hasError = true;
        }
      }); // Validation - Email

      closestForm.find('.js-email').each(function () {
        var regExp = /^\S+@\S+\.\S+$/;

        if (!regExp.test($(this).val())) {
          $(this).addClass('input__invalid');
          hasError = true;
        }
      }); // If everything is fine

      if (!hasError) {
        var data = new FormData();
        closestForm.find('.js-input').each(function () {
          data.append($(this).attr('name'), $(this).val());
        });
        var url = closestForm.attr('action');
        sendData(data, url);
      }

      return false;
    }); // Remove incorrent class when user is typing

    $('.js-required').on('input keydown propertychange', function () {
      if ($(this).val().length > 0 && $(this).val() != $(this).attr('placeholder')) {
        $(this).removeClass('input__invalid');
      }
    }); // Clear form

    function clearForm() {
      $('.js-form')[0].reset();
    } //Send data


    function sendData(data, url) {
      var messageSuccess = $('.js-message-success');
      var messageExisting = $('.js-message-existing');
      var messageError = $('.js-message-error');
      $.ajax({
        type: 'POST',
        url: url,
        data: data,
        dataType: 'json',
        cache: false,
        processData: false,
        contentType: false,
        success: function success(data) {
          if (data.status == "subscribed") {
            clearForm();
            messageSuccess.addClass('visible');
            setTimeout(function () {
              messageSuccess.removeClass('visible');
            }, 5000);
          } else if (data.status == 400) {
            messageExisting.addClass('visible');
            setTimeout(function () {
              messageExisting.removeClass('visible');
            }, 5000);
          } else {
            messageError.addClass('visible');
            setTimeout(function () {
              messageError.removeClass('visible');
            }, 5000);
          }
        },
        error: function error() {
          messageError.addClass('visible');
          setTimeout(function () {
            messageError.removeClass('visible');
          }, 5000);
        }
      });
      return false;
    }
  });
});